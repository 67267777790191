exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/Galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-trasa-js": () => import("./../../../src/pages/Trasa.js" /* webpackChunkName: "component---src-pages-trasa-js" */),
  "component---src-pages-urdb-23-cz-1-js": () => import("./../../../src/pages/urdb23cz1.js" /* webpackChunkName: "component---src-pages-urdb-23-cz-1-js" */),
  "component---src-pages-urdb-23-cz-2-js": () => import("./../../../src/pages/urdb23cz2.js" /* webpackChunkName: "component---src-pages-urdb-23-cz-2-js" */),
  "component---src-pages-urdb-23-cz-3-js": () => import("./../../../src/pages/urdb23cz3.js" /* webpackChunkName: "component---src-pages-urdb-23-cz-3-js" */),
  "component---src-pages-zapisy-js": () => import("./../../../src/pages/Zapisy.js" /* webpackChunkName: "component---src-pages-zapisy-js" */),
  "component---src-pages-zawody-js": () => import("./../../../src/pages/Zawody.js" /* webpackChunkName: "component---src-pages-zawody-js" */)
}

